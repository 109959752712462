import React from "react";
import CreateMetaComp from "../../../../../../Components/CreateMetaComp";
import { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { createMetaReq } from "../../Meta.Apis";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const CreateMeta = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    page: "",
    title: "",
    description: "",
    paragraph: "",
    keywords: ""
  });
  const [thumbnailColor, setThumbnailColor] = useState(null);
  const [storyImg, setStoryImg] = useState(null);
  const [createStoryConfirm, setCreateStoryConfirm] = useState(false);
  const { title, description, page, paragraph, keywords } = formData;

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const createMetaHandle = async () => {
    const { title, description, page, paragraph, keywords } = formData;
    try {

      await createMetaReq({
        pagePath: page,
        meta: `  <title>${title}</title>
       <meta name="description" content="${description}" />
       <meta name="keywords" content="${keywords}"/>
       <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
       <link rel="canonical" href="https://nispand.com${page}" />
       <meta property="og:locale" content="en_US" />
       <meta property="og:type" content="article" />
       <meta property="og:title" content="${title}" />
       <meta property="og:description" content="${description} " />
       <meta property="og:url" content="https://nispand.com${page}" />
       <meta property="og:site_name" content="The Yoga Institute" />
      //  <meta property="og:image" content="https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Home/tyi.png" />
         <meta property="og:image:width" content="1200" />
         <meta property="og:image:height" content="628" />
       <meta name="twitter:label1" content="Est. reading time">
         <meta name="twitter:data1" content="10 minutes">
       `,
        objectType: 'PAGE',
        pTag: paragraph
      },
      )
      setCreateStoryConfirm(true);

    } catch (error) {
      console.log("error");
    }
  };
  return (
    <>
      <CreateMetaComp
        createMetaHandle={createMetaHandle}
        handelInput={handelInput}
        formData={formData}
        setFormData={setFormData}
        title={title}
        description={description}
        page={page}
        paragraph={paragraph}
        keywords={keywords}
      />
      {createStoryConfirm ? (
        <SweetAlert
          success
          title="Created!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            // history.push("/dashboard/stories/all");
            setCreateStoryConfirm(false);
            // window.location.reload();
            history.push('/dashboard/meta/all')
          }}
        >
          Meta Data has been Created.
        </SweetAlert>
      ) : null}
    </>
  );
};
export default CreateMeta;
