import React from "react";
import {
  getAllMetaData, deleteMeta
} from "../../Meta.Apis";
import { useState, useEffect } from "react";
import { storyTabPublish } from "../../Meta.Constants";
import SweetAlert from "react-bootstrap-sweetalert";
import AllMetaList from "../../../../../../Components/AllMetaList";

const AllMeta = () => {
  const [errorRes, setErrorRes] = useState("");
  const [showErr, setShowErr] = useState(false);
  const [storyData, setStoryData] = useState([]);
  const [publishID, setPublishID] = useState("");
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");
  const [paginationValue, setPaginationValue] = useState({
    currentPage: 1,
    totalSize: 1,
  });
  const { currentPage, totalSize } = paginationValue;
  const [unpublishedPaginationValue, setUnpublishedPaginationValue] = useState({
    unpublishedCurrentPage: 1,
    unpublishedTotalSize: 1,
  });

  const [isLoading, setIsLoading] = useState(false);

  const getAllMeta = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getAllMetaData(queryParams);
      // alert(data)
      setPaginationValue({
        currentPage: queryParams.page,
        totalSize: data.count,
      });
      setStoryData(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllUnPublishedStories = async (queryParams) => {
    try {
      setIsLoading(true);
      const { data } = await getUnPublishedStoryApi(queryParams);
      setUnpublishedPaginationValue({
        unpublishedCurrentPage: queryParams.page,
        unpublishedTotalSize: data.count,
      });
      setStoryDataUnpublish(data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllMeta({ page: 1, limit: 100 });
    // getAllUnPublishedStories({ page: 1, limit: 10 });
  }, []);

  const handleUnpublishStoryClick = async (storyId) => {
    setUnpublishId(storyId);
    setShowUnPublishAlert(true);
  };
  const handlePublishedTablePageChange = (type, { page, sizePerPage }) => {
    getAllMeta({ page, limit: sizePerPage });
    setPaginationValue({
      ...paginationValue,
      currentPage: page,
    });
  };
  const handleUnpublishedTablePageChange = (type, { page, sizePerPage }) => {
    getAllUnPublishedStories({ page, limit: sizePerPage });
    setUnpublishedPaginationValue({
      ...unpublishedPaginationValue,
      unpublishedCurrentPage: page,
    });
  };

  const unpublishStoryAction = async () => {
    try {
      await deleteMeta(unpublishId);
      getAllMeta({ page: 1, limit: 100 });
      setUnpublishId("");
      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      console.log(error, "error.response.data.error");
      setErrorRes(error.response.data.error);
      setShowErr(true);
    }
  };

  const handlePublishStoryClick = async (storyId) => {
    setPublishID(storyId);
    setShowPublishAlert(true);
  };

  const publishStoryAction = async () => {
    try {
      await publishStory(publishID);
      setPublishID("");
      setShowPublishAlert(false);
    } catch (error) { }
  };

  return (
    <>
      <AllMetaList
        tableData={storyData}
        tableHeading={storyTabPublish}
        deleteMeta={handleUnpublishStoryClick}
        handleTablePageChange={handlePublishedTablePageChange}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        isLoading={isLoading}
      />

      {showErr ? (
        <SweetAlert
          warning
          title={errorRes}
        // onConfirm={() => window.location.reload()}
        ></SweetAlert>
      ) : null}

      {showUnPublishAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, Delete it!"
          reverseButtons={true}
          onConfirm={() => {
            unpublishStoryAction()
            setShowUnPublishAlert(false)
          }}
          onCancel={() => setShowUnPublishAlert(false)}
        ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Deleted"
          onConfirm={() => {
            setShowUnPublishAlert(false);
            setShowUnPublishConfirm(false);
            // window.location.reload();
          }}
        ></SweetAlert>
      ) : null}

    </>
  );
};

export default AllMeta;
