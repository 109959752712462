import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Button,
  Input,
} from "reactstrap";

const EditMetaComp = ({
  editMetaHandle,
  handelInput,
  title,
  description,
  page, paragraph, keywords
}) => {
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Edit Page Meta Data</h3>
                  </div>
                </Col>
              </Row>
              <AvForm
                className="form-horizontal"
              >
                <Row>
                  <Col className="mt-3">
                    <FormGroup>
                      <Label>Page</Label>
                      <AvForm>
                        <AvField
                          type='text'
                          name='page'
                          placeholder='Enter Page Path'
                          value={page}
                          onChange={handelInput}
                          errorMessage="Title is required"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="page"
                          disabled
                        />
                      </AvForm>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <FormGroup>
                      <Label>Title</Label>
                      <AvForm>
                        <AvField
                          type='text'
                          name='title'
                          placeholder='Enter Meta Title'
                          value={title}
                          onChange={handelInput}
                          errorMessage='Please enter title'
                          validate={{ required: { value: true } }}
                        />
                      </AvForm>
                    </FormGroup>
                  </Col>
                </Row>
                <Row></Row>

                <Row>
                  <Col className="mt-3">
                    <FormGroup>
                      <Label>Description</Label>
                      <AvForm>
                        <AvField
                          type='text'
                          name='description'
                          placeholder='Enter Description'
                          value={description}
                          onChange={handelInput}
                          errorMessage='Please enter description'
                          validate={{ required: { value: true } }}
                        />
                      </AvForm>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3 ">
                    <FormGroup>
                      <Label>Paragraph</Label>
                      <AvForm>
                        <AvField
                          type='text'
                          name='paragraph'
                          value={paragraph}
                          onChange={handelInput}
                          placeholder='Enter Meta Paragraph'
                          errorMessage='Please enter paragraph'
                          validate={{ required: { value: true } }}
                        />
                      </AvForm>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3 ">
                    <FormGroup>
                      <Label>Keywords</Label>
                      <AvForm>
                        <AvField
                          type='text'
                          name='keywords'
                          value={keywords}
                          onChange={handelInput}
                          placeholder='Enter Keywords'
                          errorMessage='Please enter keywords'
                          validate={{ required: { value: true } }}
                        />
                      </AvForm>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  className="w-100 mt-4"
                  type="submit"
                  onClick={() => editMetaHandle()}
                  disabled={!description || !title || !page || !paragraph || !keywords}                >
                  Edit Page Meta Data
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default EditMetaComp;
