import { EditUserApi } from "../../../../../Constants/apiRoutes";
import AllMeta from "../Components/AllMeta";
import CreateMeta from "../Components/CreateMeta";
import EditMeta from "../Components/EditMeta";

export const MetaRoutes = [

  {
    Component: AllMeta,
    path: "/all",
    exact: true,
    id: "dashboardMetaAll",
    routePath: "/dashboard/meta/all",
    name: "All Meta Data",
  },
  {
    Component: CreateMeta,
    path: "/create",
    exact: true,
    id: "dashboardStoriesCreate",
    routePath: "/dashboard/meta/create",
    name: "Create Meta",
  },
  {
    Component: EditMeta,
    path: "/edit/:storyId",
    exact: true,
    id: 'dashboardStoriesEdit',
  },

];


