import React from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { useState, useEffect } from "react";
import Table from "../Table";
import { useHistory } from "react-router-dom";

const AllMetaList = ({
  heading = "Page Meta Data",
  tableData = [],
  tableHeading = [],
  deleteMeta,
  handleTablePageChange,
  page,
  isLoading,
}) => {
  const [rowData, setRowData] = useState([]);
  const history = useHistory();

  const handleEditStoryClick = (questionId) => {
    history.push("/dashboard/meta/edit/" + questionId);
  };


  const viewSeriesFormatter = (cell, row) => {
    return (
      <div style={{ textAlign: "center" }} key={row._id}>
        {/* width: '200px',, display: 'flex', gap: '20px'  */}
        <div style={{ textAlign: "center" }} key={row._id}>
          <i
            className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
            onClick={() => {
              handleEditStoryClick(row._id);
            }}
          />
        </div>

        {/* <Button
          color="danger"
          className="waves-effect waves-light"
          style={{ width: "100%" }}
          onClick={() => deleteMeta(row._id)}
        >
          Delete
        </Button> */}

      </div>
    );
  };
  useEffect(() => {

    tableHeading.push({
      text: "Action",
      dataField: "publish",
      isDummyField: true,
      headerStyle: { textAlign: "center", width: "10%" },
      formatter: viewSeriesFormatter,
    });
    return () => {
      tableHeading.pop();
    };
  }, []);

  useEffect(() => {
    if (tableData) {
      var output = tableData.map((e, i) => {
        var x = Object.assign({ ...e });
        x.sNo = i + 1 + page * 10 - 10;
        return x;
      });
      setRowData(output);
    }
  }, [tableData]);
  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className="m-0">{heading}</h3>
                  </div>
                </Col>
              </Row>
              <Table
                rows={rowData}
                columns={tableHeading}
                onTableChange={handleTablePageChange}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AllMetaList;
