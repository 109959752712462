import React from "react";
import { useState, useEffect } from "react";
import { getMetaById, updateMeta, updateStory } from "../../Meta.Apis";
import { useHistory } from "react-router-dom";
import EditMetaComp from "../../../../../../Components/EditMetaComp";
import SweetAlert from "react-bootstrap-sweetalert";

const decodeHtmlEntities = (text) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
};

const metaParseAlgo = (data = '') => {
  let headers = {
    title: '',
    links: [],
    metaData: [],
    script: '',
  };

  // Decode HTML entities before processing the string
  data = decodeHtmlEntities(data);

  data = data.replace(/\\n/g, '');
  data = data.split('\n');

  data.forEach((el) => {
    if (el.includes('<meta') || el.includes('<link')) {
      let obj = {};

      let regExp = /(\S+)="[^"]*/g;
      let regexMatches = el.match(regExp);

      regexMatches.map((el) => {
        let partition = el.split('="');
        obj[partition[0]] = partition[1].replace(/"/g, '');
      });

      if (el.includes('<meta')) headers.metaData.push(obj);
      if (el.includes('<link')) headers.links.push(obj);
    } else if (el.includes('<title')) {
      headers.title = el.replace('<title>', '').replace('</title>', '');
    } else if (el.includes('<script')) {
      headers.script = el;
    }
  });

  return {
    title: headers.title.trim(),
    description: headers.metaData.find((item) => {
      return item.name === 'description';
    }),
    keywords: headers.metaData.find((item) => {
      return item.name === 'keywords';
    }),
  };
};

const EditMeta = () => {
  const history = useHistory();
  const [id, setId] = useState(null);  // alert(metaIds)
  const [formData, setFormData] = useState({
    id: '',
    page: "",
    title: "",
    description: "",
    paragraph: "",
    keywords: ""
  });
  const { title, description, page, paragraph, keywords } = formData;
  const [updateStoryConfirm, setUpdateStoryConfirm] = useState(false);

  const handelInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getMetaId = async (metaId) => {
    try {
      const { data } = await getMetaById(metaId);
      const parsedData = metaParseAlgo(data?.data?.meta)
      setFormData({
        id: metaId,
        page: data?.data?.pagePath,
        title: parsedData.title.trim(),
        description: parsedData.description?.content,
        paragraph: data?.data?.pTag,
        keywords: parsedData.keywords?.content
      })
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const url = window.location.href; // Get the full URL
    const segments = url.split('/'); // Split the URL by "/"
    const lastSegment = segments[segments.length - 1]; // Get the last segment (the ID)
    setId(lastSegment); // Set the ID to state
    getMetaId(lastSegment);
  }, []);

  const updateMetaData = async () => {
    const { title, description, page, paragraph, keywords } = formData;
    try {
      await updateMeta(id, {
        pagePath: page,
        meta: `  <title>${title}</title>
       <meta name="description" content="${description}" />
       <meta name="keywords" content="${keywords}"/>
       <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
       <link rel="canonical" href="https://nispand.com${page}" />
       <meta property="og:locale" content="en_US" />
       <meta property="og:type" content="article" />
       <meta property="og:title" content="${title}" />
       <meta property="og:description" content="${description} " />
       <meta property="og:url" content="https://nispand.com${page}" />
       <meta property="og:site_name" content="The Yoga Institute" />
      //  <meta property="og:image" content="https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Home/tyi.png" />
         <meta property="og:image:width" content="1200" />
         <meta property="og:image:height" content="628" />
       <meta name="twitter:label1" content="Est. reading time">
         <meta name="twitter:data1" content="10 minutes">
       `,
        objectType: 'PAGE',
        pTag: paragraph
      },
      )
      setUpdateStoryConfirm(true);
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      <EditMetaComp
        editMetaHandle={updateMetaData}
        handelInput={handelInput}
        formData={formData}
        setFormData={setFormData}
        title={title}
        description={description}
        page={page}
        paragraph={paragraph}
        keywords={keywords}
      />
      {updateStoryConfirm ? (
        <SweetAlert
          success
          title="Updated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            history.push("/dashboard/meta/all");
            setUpdateStoryConfirm(false);
          }}
        >
          Meta data has been updated.
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditMeta;
