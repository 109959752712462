export const storyTabPublish = [
  {
    text: "S.No",
    dataField: "sNo"
  },

  {
    text: "Path",
    dataField: "pagePath"
  }
]

