import React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { MetaRoutes } from "./constants/routes";

const Meta = () => {
  const match = useRouteMatch();
  return (
    <>
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/all`} />
        {MetaRoutes.map(({ path, Component, exact, id }) => (
          <Route
            path={`${match.url}${path}`}
            exact={exact}
            component={Component}
            key={id}
          />
        ))}
        <Redirect to="/dashboard/stories" />
      </Switch>
    </>
  );
};
export default Meta