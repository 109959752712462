import axios from "axios";
import { stories, baseDomain, stage, meta } from "../../../../Constants/apiRoutes";


export const createMetaReq = (payload) => {
  return axios.post(`${baseDomain}/v1${meta}`, payload);
};

export const getAllMetaData = ({ page, limit }) => {
  return axios.post(`${baseDomain}/v1${meta}/list`, { page, limit });
};

export const getMetaById = (id) => {
  return axios.get(`${baseDomain}/v1${meta}/${id}`);
};

export const updateMeta = (id, payload) => {
  return axios.put(`${baseDomain}/v1${meta}/${id}`, payload);
};

export const deleteMeta = (metaId) => {
  return axios.delete(`${baseDomain}/v1${meta}/${metaId}`);
};